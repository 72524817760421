<template>
  <div>
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header></t-farmon-table-header>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-5 font-weight-bold text-center">
        <div class="row align-items-center">
          <div class="col-12 text-right align-items-center mb-5">
            <span class="border rounded bg-secondary p-3">
              <button class="btn rounded-lg border border-rounded border-dark p-1 btn-white"><i class="flaticon2-bell-2 p-0" style="font-size: 1.6rem;"></i></button>
              <span class="pr-2" style="font-size: 1.2rem;"> : {{ i18n.haveToAction }}</span>
            </span>
          </div>
          <div class="col-12 text-right align-items-center mb-12">
            <button class="btn rounded-lg py-4 btn-success"></button>
            <span class="pr-2" style="font-size: 1.2rem;"> : {{ i18n.noDeviation }}</span>
            <button class="btn rounded-lg py-4 btn-danger"></button>
            <span class="pr-2" style="font-size: 1.2rem;"> : {{ i18n.yesDeviation }}</span>
          </div>
        </div>
        <div class="row align-items-center">
          <span v-for="(env, idx) in envsTitle" :key="idx" :style="`width: ${(100 / envsTitle.length).toFixed(0)}%;`"> {{ env }} </span>
        </div>
        <div class="row align-items-center mb-2">
          <span v-for="(icon, idx) in envesIcon" :key="idx" :style="`width: ${(100 / envsTitle.length).toFixed(0)}%;`"> <b-icon :icon="`${icon}`" scale="1" variant="danger"></b-icon> </span>
        </div>
        <div v-for="(connect, idx) in farmConnects" :key="idx" class="row align-items-center text-dark alert-light border rounded my-2">
          <div v-for="(prefix, deviationIdx) in envsPrefix" :key="deviationIdx" :style="`width: ${(100 / envsTitle.length).toFixed(0)}%;`">
            <div v-if="deviationIdx === 0">{{ connect.connectNm }}</div>
            <div v-if="deviationIdx !== 0">
              <button :class="`btn rounded-lg my-3 py-4 w-75 ${connect[prefix + 'Deviation'] === 'N' ? 'btn-success': connect[prefix + 'Deviation'] === 'Y' ? 'btn-danger' : 'btn-secondary'}`" style="position: relative;" @click="buttonClicked(idx, deviationIdx, true)">
                <span v-show="!screenMobile"> {{ connect[prefix + 'Value'] }}</span>
                <span v-show="actionRequired[connect.systemId].includes(deviationIdx)" :style="`opacity: ${bellRing}; ${!screenMobile ? 'position: absolute; right: 10%; top: 10%;' : 'position: absolute;'}`">
                  <i class="flaticon2-bell-2 p-0" style="font-size: 1.5rem;"></i>
                </span>
              </button>
            </div>
          </div>
          <div>
            <hr>
          </div>
        </div>
      </div>
    <!--end::Body-->
    </div>

    <!-- begin::NotificationDetailModal -->
    <b-modal
      v-model="showDetailModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">{{ i18n.deviationStatus }}</span>
        </h3>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-8">
          <label class="col-sm-12 col-form-label">
            <span class="h5">{{ i18n.deviationTimeseries }}</span>
          </label>
          <div class="col-sm-12 col-form-label p-5" style="background-color: #F9FBFF">
            <notification-action-time-chart
              :category="envCategory"
              :chart-series="envChartSeries"
              :notification-chart-series="notificationChartSeries"
              :limithigh="envLimithigh"
              :limitlow="envLimitlow"
              :management-chart-series="managementChartSeries"
              :loading="loading"
              :buttonLeftRight="buttonLeftRight"
              :showLeftRightButton="showLeftRightButton"
              class="border border-0 rounded"
            ></notification-action-time-chart>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-12 col-form-label">
            <span class="h5">{{ i18n.notificationLog }}</span>
          </label>
          <div class="col-sm-12">
            <v-data-table
              :headers="fields"
              :items="notificationLogs"
              class="elevation-1 farm-data-table"
              no-data-text="No Data"
              hide-default-footer
          >
          </v-data-table>
          </div>
        </div>
      </div>
      <!-- end::Body -->
      <!--begin::Footer-->
        <div class="card-footer">
          <b-button variant="secondary" size="lg" class="ml-3 float-right" @click="showDetailModal = false"><span class="">{{ i18n.cancel }}</span></b-button>
        </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::NotificationDetailModal -->

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TFarmonTableHeader from "@/components/TFarmonTableHeader";
import {
  MUT_SET_FARM_ID,
  ACT_GET_MY_FARM,
  ACT_GET_STATUS_LOGS,
  ACT_GET_FARM_THRESHOLD,
  ACT_GET_DEVIATION_STATUS,
  ACT_GET_NOTIFICATION_DEVIATION,
  ACT_GET_ALARM_MANAGEMENT,
  ACT_GET_NOTIFICATION_DETAIL,
  ACT_GET_MY_CONNECTS } from '@/core/services/variable';
import { lan, getItem, getItems, utcToDate, timestampToDate } from '@/core/services/funcs'; // getItems, isSuccess, lengthCheck
import NotificationActionTimeChart from '@/components/NotificationActionTimeChart';
import i18n from "@/core/plugins/vue-i18n.js";

export default {
  name: "NotificationActionNew",
  components: {
    TFarmonTableHeader,
    NotificationActionTimeChart
  },
  mounted() {
    setInterval(() => {
      this.bellRing = this.bellRing === 0 ? 1 : 0
    }, 1000)
    this.$store.commit(MUT_SET_FARM_ID, this.$route.params.farmId)

    window.scrollTo(0, 0)
    window.addEventListener('load', this.handleResize)
    window.addEventListener('resize', this.handleResize)
    setTimeout(this.handleResize, 1000)
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: i18n.t("topNavBar.farm.analysis"), icon: "fas fa-clipboard" },
      { title: i18n.t("topNavBar.farm.farmDeviationStatusInfo") },
    ])
    this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
      this.fno = getItem(resp).fno
      this.connects = getItem(resp).farmConnects
      this.getThreshold()
      this.setDeviationInterval()
      this.connects.forEach(connect => {
        if(connect['waterTempYn'] === 'Y') {
          this.envsTitle= [
          '',
          i18n.t('farm.deviationStatus.temperature'),
          i18n.t('farm.deviationStatus.humidity'),
          'CO2', 'EC', 'pH', 
          i18n.t('farm.deviationStatus.waterTemp'),
          i18n.t('farm.deviationStatus.waterLevel'),
          i18n.t('farm.deviationStatus.waterPump'),
          i18n.t('farm.deviationStatus.recieveData')
          ]
          this.envsChar = ['','tempDay', 'humidityDay', 'co2Day', 'ec', 'ph', 'waterTemp', 'waterLevel', 'waterPump', 'dataReceive']
          this.envsPrefix = ['','temp', 'humidity', 'co2', 'ec', 'ph', 'waterTemp', 'waterLevel', 'pureLevelAbc', 'dataReceive']
          this.notificationItem = ['', 'Temperature', 'Humidity', 'CO2', 'EC', 'PH', 'WaterTemperature', '양액 수위', '양액 AB 보충,양액 C 보충', '데이터 미수신']
        }
      })
      if(this.$route.params.rootAlarmSeq !== undefined){
        this.getNotificationWithRootAlarmSeq()
      }
    })
    this.getDates(0)
  },
  destroyed() {
    this.clearDeviationInterval();
  },
  data() {
    return {
      locale: lan(),
      i18n: {
        noDeviation: i18n.t('farm.deviationStatus.noDeviation'),
        yesDeviation: i18n.t('farm.deviationStatus.yesDeviation'),
        haveToAction: i18n.t('farm.deviationStatus.haveToAction'),

        deviationStatus: i18n.t('farm.deviationStatus.deviationStatus'),
        deviationTimeseries: i18n.t('farm.deviationStatus.deviationTimeseries'),
        notificationLog: i18n.t('farm.deviationStatus.notificationLog'),
        cancel: i18n.t('farm.deviationStatus.cancel'),

        deviationAction: i18n.t('farm.deviationStatus.deviationAction'),
        actionDate: i18n.t('farm.deviationStatus.actionDate'),
        actionDesc: i18n.t('farm.deviationStatus.actionDesc'),
        pauseNotification: i18n.t('farm.deviationStatus.pauseNotification'),
        hour: i18n.t('farm.deviationStatus.hour'),
      },
      fno :0,
      thresholdData: {},
      farmConnects: [],
      connects: [],
      envsTitle: [
        '',
        i18n.t('farm.deviationStatus.temperature'),
        i18n.t('farm.deviationStatus.humidity'),
        'CO2', 'EC', 'pH', 
        i18n.t('farm.deviationStatus.waterLevel'),
        i18n.t('farm.deviationStatus.waterPump'),
        i18n.t('farm.deviationStatus.recieveData')
      ],
      envesIcon: [
        '',
        'thermometer-half',
        'droplet',
        'share',
        'lightning',
        'funnel',
        'align-top',
        'align-bottom',
        'wifi-off',
      ],

      systemIdsEnvTypes: [],
      showNotificationActionModal: false,
      actionDesc: '',
      setAlarmPause: false,
      alarmPauseTime: 12,
      actionDate: '2023-01-12',
      actionTime: '12:00',

      showDetailModal: false,
      idxs: [0,0],
      systemId: 200021,
      connectNm: '',
      startYmd: '',
      endYmd: '',
      dayCount: 0,
      envChartSeries: [],
      notificationChartSeries: [],
      managementChartSeries: [],
      envCategory: '',
      envsChar: ['','tempDay', 'humidityDay', 'co2Day', 'ec', 'ph', 'waterLevel', 'waterPump', 'dataReceive'],
      envsPrefix: ['','temp', 'humidity', 'co2', 'ec', 'ph', 'waterLevel', 'pureLevelAbc', 'dataReceive'],
      notificationItem: ['', 'Temperature', 'Humidity', 'CO2', 'EC', 'PH', '양액 수위', '양액 AB 보충,양액 C 보충', '데이터 미수신'],
      envLimithigh: 0,
      envLimitlow: 0,
      loading: false,
      screenMobile: false,
      fields: [
        { value: 'createDt', text: i18n.t('farm.deviationStatus.date'), width: '120px'},
        { value: 'connectNm', text: i18n.t('farm.deviationStatus.connectNm')},
        { value: 'envType', text: i18n.t('farm.deviationStatus.envType')},
        { value: 'value', text: i18n.t('farm.deviationStatus.envValue')},
        { value: 'manageYn', text: i18n.t('farm.deviationStatus.manageYn')},
        { value: 'memberNm', text: i18n.t('farm.deviationStatus.memberNm')},
        { value: 'sendTarget', text: i18n.t('farm.deviationStatus.sendTarget')},
      ],
      notificationLogs: [],
      usePause: true,
      statusInterval: null,
      showLeftRightButton: false,
      actionRequired: {},
      bellRing: 0
    }
  },
  watch: {
    actionDate(val){
      const curDate = new Date(utcToDate(new Date(), 'yyyy-MM-dd'))
      if(new Date(val) < curDate){
        this.usePause = false
      }else{
        this.usePause = true
      }
    },
    alarmPauseTime(val){
      if(val < 0 || val > 24) {
        this.alarmPauseTime = null
      }
    }
  },
  methods: {
    setDeviationInterval(){
      this.clearDeviationInterval()
      this.getDeviationStatus()
      this.deviationInterval = setInterval(() => {
        this.getDeviationStatus()
      }, 60000);
    },
    clearDeviationInterval(){
      if(this.deviationInterval) {
        clearInterval(this.deviationInterval);
        this.deviationInterval = null;
      }
    },
    addNotifications(){
      this.notificationLogs.forEach(item => {
        this.envChartSeries.push([item.createDt.replaceAll('-', '/'), item.value])
        this.notificationChartSeries.push([item.createDt.replaceAll('-', '/'), item.value])
      })

      this.sortChart()
    },
    sortChart(){
      this.envChartSeries = this.envChartSeries.sort((a,b) => {
        return new Date(a[0]).getTime() - new Date(b[0]).getTime()
      })
    },
    getDates(count){
      let startCurrDate = new Date();
      let endCurrDate = new Date();
      const startDate = utcToDate(new Date(startCurrDate.setDate(startCurrDate.getDate() - 5 + count)), 'yyyyMMdd hh:mm')
      this.startYmd = startDate
      const endDate = utcToDate(new Date(endCurrDate.setDate(endCurrDate.getDate() + count)), 'yyyyMMdd hh:mm')
      this.endYmd = endDate
    },
    buttonLeftRight(direction){
      if(direction == 'right' && this.dayCount < 0){
        this.dayCount += 1
      }else if(direction == 'left'){
        this.dayCount -= 1
      }
      this.getDates(this.dayCount)
      this.buttonClicked(this.idxs[0], this.idxs[1], false)
    },
    getDeviationStatus() {
      this.$store.dispatch(ACT_GET_DEVIATION_STATUS, this.fno).then(resp => {
        this.farmConnects = getItems(resp)
        this.actionRequired = {}
        for(let i=0; i< this.farmConnects.length; i++){
          this.envsPrefix.forEach(prefix => {
            if(prefix == 'temp' || prefix == 'humidity' || prefix == 'ph'){
              this.farmConnects[i][prefix + 'Value'] = this.farmConnects[i][prefix + 'Value'] == 'N/A' || this.farmConnects[i][prefix + 'Value'] == 0 ? '-' : (this.farmConnects[i][prefix + 'Value']/100).toFixed(2)
              this.farmConnects[i][prefix + 'Deviation'] = this.farmConnects[i][prefix + 'Value'] ==  '-' ? 'NoData' : this.farmConnects[i][prefix + 'Deviation'];
            }else if(prefix == 'ec'){
              this.farmConnects[i][prefix + 'Value'] = this.farmConnects[i][prefix + 'Value'] == 'N/A' || this.farmConnects[i][prefix + 'Value'] == 0 ? '-' : (this.farmConnects[i][prefix + 'Value']/1000).toFixed(2)
              this.farmConnects[i][prefix + 'Deviation'] = this.farmConnects[i][prefix + 'Value'] ==  '-' ? 'NoData' : this.farmConnects[i][prefix + 'Deviation'];
            }else if(prefix == 'waterTemp'){
              this.farmConnects[i][prefix + 'Value'] = this.farmConnects[i][prefix + 'Value'] == 'N/A' || this.farmConnects[i][prefix + 'Value'] == 0 ? '-' : (this.farmConnects[i][prefix + 'Value']/100).toFixed(2)
              this.farmConnects[i][prefix + 'Deviation'] = this.farmConnects[i][prefix + 'Value'] ==  '-' ? 'NoData' : this.farmConnects[i][prefix + 'Deviation'];
            }else if(prefix == 'waterLevel'){
              this.farmConnects[i].waterLevelValue = this.farmConnects[i].waterLevelValue == '2' ? 'High' : this.farmConnects[i].waterLevelValue == '1' ? 'Mid' : this.farmConnects[i].waterLevelValue == '0' ? 'Low' : '-'
              this.farmConnects[i].waterLevelDeviation = this.farmConnects[i].waterLevelValue ==  '-' ? 'NoData' : this.farmConnects[i].waterLevelDeviation
            }else if(prefix == 'pureLevelAbc'){
              this.farmConnects[i].pureLevelAbValue = this.farmConnects[i].pureLevelAbValue == 'N/A' ? '-' :  this.farmConnects[i].pureLevelAbValue == 0 ? 'OFF' : 'ON'
              this.farmConnects[i].pureLevelCValue = this.farmConnects[i].pureLevelCValue == 'N/A' ? '-' :  this.farmConnects[i].pureLevelCValue == 0 ? 'OFF' : 'ON'
              this.farmConnects[i].pureLevelAbcValue = this.farmConnects[i].pureLevelAbValue + '/' + this.farmConnects[i].pureLevelCValue
              this.farmConnects[i].pureLevelAbcDeviation = this.farmConnects[i].pureLevelAbcValue.match(/[-]/g) === null ? this.farmConnects[i].pureLevelAbcDeviation : 'NoData'
            }
            if(this.farmConnects[i].actionRequiredList !== []){
              this.actionRequired[this.farmConnects[i].systemId] = this.farmConnects[i].actionRequiredList.map(required => {
                return this.notificationItem.findIndex(item => item === required)
              })
            }
          })

          if(this.farmConnects[i].dataReceiveDeviation === 'N'){
            this.farmConnects[i].dataReceiveValue = 'ON'
          }else{
            this.envsPrefix.forEach(prefix => {
              if(prefix != ''){
                this.farmConnects[i][prefix + 'Value'] = '-'
                this.farmConnects[i][prefix + 'Deviation'] = 'NoData'
              }
            })
            this.farmConnects[i].dataReceiveDeviation = 'Y'
            this.farmConnects[i].dataReceiveValue = 'OFF'
          }
        }
      })
    },
    handleResize(){
      if(window.innerWidth < window.innerHeight){
        this.screenMobile = true
      }else{
        this.screenMobile = false
      }
    },
    getThreshold() {
      this.$store.dispatch(ACT_GET_FARM_THRESHOLD, this.fno).then(resp =>{
        if(!(resp.items.length == 0)){
          this.thresholdData = resp.items[0]
        }else{
          this.$store.dispatch(ACT_GET_FARM_THRESHOLD, 0).then(baselineResp =>{
            this.thresholdData = baselineResp.items[0]
          })
        }
        this.thresholdData.waterLevelUrgenthigh = null
        this.thresholdData.waterLevelUrgentlow = null
        this.thresholdData.waterPumpUrgenthigh = null
        this.thresholdData.waterPumpUrgentlow = null
        this.thresholdData.dataReceiveUrgenthigh = null
        this.thresholdData.dataReceiveUrgentlow = null
      })
    },
    getNotificationWithDate(idx){
      this.notificationLogs = []
      this.managementChartSeries = []
      let params = {}
      if(idx === 7){
        const envType = this.notificationItem[idx].split(',')
        params = {
          fno: this.fno,
          envType: envType[0],
          startYmd: this.startYmd.slice(0,8),
          endYmd: this.endYmd.slice(0,8),
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }
        this.$store.dispatch(ACT_GET_NOTIFICATION_DEVIATION, {fno: this.fno, systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, envType: `${envType[0]}`}).then(resp => {
          getItems(resp).forEach(item => {
            this.notificationLogs.push({
              'createDt': item.createDt.slice(0, 19),
              'connectNm': this.connectNm,
              'envType': item.envType,
              'value': item.envValue == 'N/A' || item.envValue == null ? 0 : item.envValue,
              'manageYn': item.manageYn,
              'memberNm': item.memberNm,
              'sendTarget': item.sendTarget
            })
          })
          this.$store.dispatch(ACT_GET_NOTIFICATION_DEVIATION, {fno: this.fno, systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, envType: `${envType[1]}`}).then(resp => {
            getItems(resp).forEach(item => {
              this.notificationLogs.push({
                'createDt': item.createDt.slice(0, 19),
                'connectNm': this.connectNm,
                'envType': item.envType,
                'value': item.envValue == 'N/A' || item.envValue == null ? 0 : item.envValue,
                'manageYn': item.manageYn,
                'memberNm': item.memberNm,
                'sendTarget': item.sendTarget
              })
            })
            this.addNotifications()
          })
        })
        this.$store.dispatch(ACT_GET_ALARM_MANAGEMENT, params).then(resp0 => {
          getItems(resp0).forEach(item => {
            if(item.systemId === this.systemId){
              let refinedDatetime = new Date(item.managementDt.replaceAll('-','/'))
              refinedDatetime.setHours(refinedDatetime.getHours() + 9)
              let managementDt = refinedDatetime.getTime()

              this.managementChartSeries.push({x: managementDt, title: '일탈 조치', text: item.managementContent})
            }
          })
          params.envType = envType[1]
          this.$store.dispatch(ACT_GET_ALARM_MANAGEMENT, params).then(resp1 => {
            getItems(resp1).forEach(item => {
              if(item.systemId === this.systemId){
                let refinedDatetime = new Date(item.managementDt.replaceAll('-','/'))
                refinedDatetime.setHours(refinedDatetime.getHours() + 9)
                let managementDt = refinedDatetime.getTime()

                this.managementChartSeries.push({x: managementDt, title: '일탈 조치', text: item.managementContent})
              }
            })
          })
        })
      }else{
        params = {
          fno: this.fno,
          envType: `${this.notificationItem[idx]}`,
          startYmd: this.startYmd.slice(0,8),
          endYmd: this.endYmd.slice(0,8),
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }
        this.$store.dispatch(ACT_GET_NOTIFICATION_DEVIATION, {fno: this.fno, systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, envType: `${this.notificationItem[idx]}`}).then(resp => {
          getItems(resp).forEach(item => {
            this.notificationLogs.push({
              'createDt': item.createDt.slice(0, 19),
              'connectNm': this.connectNm,
              'envType': item.envType,
              'value': item.envValue == 'N/A' || item.envValue == null ? 0 : item.envValue,
              'manageYn': item.manageYn,
              'memberNm': item.memberNm,
              'sendTarget': item.sendTarget
            })
          })
          this.$store.dispatch(ACT_GET_ALARM_MANAGEMENT, params).then(resp => {
            getItems(resp).forEach(item => {
              if(item.systemId === this.systemId){
                let refinedDatetime = new Date(item.managementDt.replaceAll('-','/'))
                refinedDatetime.setHours(refinedDatetime.getHours() + 9)
                let managementDt = refinedDatetime.getTime()

                this.managementChartSeries.push({x: managementDt, name: item.managementContent})
              }
            })
          })
          this.addNotifications()
        })
      }
    },
    getNotificationWithRootAlarmSeq() {
      this.loading = true
      this.showDetailModal = true
      this.envChartSeries = []
      this.notificationChartSeries = []
      this.notificationLogs = []

      this.$store.dispatch(ACT_GET_NOTIFICATION_DETAIL, this.$route.params.rootAlarmSeq).then(resp => {
        this.$store.dispatch(ACT_GET_MY_CONNECTS).then(res => {
          this.connectNm = getItem(res).farmConnects.find(connect => connect.systemId === getItems(resp)[0].systemId).connectNm

          getItems(resp).forEach((item, idx) => {
            if(idx === 0){
              this.startYmd = item.createDt.slice(0, 10).replaceAll('-', '')
              this.idx = this.notificationItem.findIndex(noti => noti.includes(item.envType))
              this.envLimithigh = this.thresholdData[this.envsChar[this.idx]+'Urgenthigh']
              this.envLimitlow = this.thresholdData[this.envsChar[this.idx]+'Urgentlow']
              this.envCategory = this.envsPrefix[this.idx].toUpperCase()
              this.systemId = item.systemId

              const growType = this.connects.find(connect => connect.systemId == item.systemId).growType
              const envType = this.envsChar[this.idx]
              if(envType == 'ec'){
                if(growType == '육묘'){
                  this.envLimithigh = this.thresholdData['seedEcUrgenthigh']
                  this.envLimitlow = this.thresholdData['seedEcUrgentlow']
                }else if(growType == '이식'){
                  this.envLimithigh = this.thresholdData['transplantEcUrgenthigh']
                  this.envLimitlow = this.thresholdData['transplantEcUrgentlow']
                }
              }
            }
            if(idx === getItems(resp).length - 1){
              this.endYmd = item.createDt.slice(0, 10).replaceAll('-', '')
            }
          })

          if(this.envsPrefix[this.idx] == 'waterLevel'){
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `water_level`}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
              })
              this.sortChart()
              this.loading = false
            })
          }else if(this.envsPrefix[this.idx] == 'waterTemp'){
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `water_temp_value`}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/100])
              })
              this.sortChart()
              this.loading = false
            })
          }else if(this.envsPrefix[this.idx] == 'pureLevelAbc'){
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `pure_level_ab`}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
              })
              this.sortChart()
              this.loading = false
            })
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `pure_level_c`}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
              })
              this.sortChart()
              this.loading = false
            })
          }else if(this.envsPrefix[this.idx] == 'dataReceive'){
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `data_receive`, createEmpty: 'true'}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, 1])
              })
              this.sortChart()
              this.loading = false
            })
          }else{
            this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `${this.envsPrefix[this.idx]}_value`}).then(resp => {
              getItems(resp).forEach((log) =>{
                const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
                const prefix = this.envsPrefix[this.idx]
                if(prefix == 'temp' || prefix == 'humidity' || prefix == 'ph') {
                  this.envChartSeries.push([key, log.value/100])
                }else if(prefix == 'ec') {
                  this.envChartSeries.push([key, log.value/1000])
                }else {
                  this.envChartSeries.push([key, log.value/1])
                }
              })
              this.sortChart()
              this.loading = false
            })
          }
          this.getNotificationWithDate(this.idx)
        })
      })
    },
    buttonClicked(i, idx, btnLeftRight) {
      this.showLeftRightButton = true
      if(btnLeftRight == true) {
        this.dayCount = 0
        this.getDates(this.dayCount)
      }

      this.loading = true
      this.idxs = [i, idx]
      this.showDetailModal = true
      this.envChartSeries = []
      this.notificationChartSeries = []
      this.envLimithigh = this.thresholdData[this.envsChar[idx]+'Urgenthigh']
      this.envLimitlow = this.thresholdData[this.envsChar[idx]+'Urgentlow']
      this.systemId = this.farmConnects[i].systemId
      this.connectNm = this.farmConnects[i].connectNm
      this.envCategory = this.envsPrefix[idx].toUpperCase()

      const growType = this.connects.find(connect => connect.systemId == this.systemId).growType
      const envType = this.envsChar[idx]

      if(envType == 'ec'){
        if(growType == '육묘'){
          this.envLimithigh = this.thresholdData['seedEcUrgenthigh']
          this.envLimitlow = this.thresholdData['seedEcUrgentlow']
        }else if(growType == '이식'){
          this.envLimithigh = this.thresholdData['transplantEcUrgenthigh']
          this.envLimitlow = this.thresholdData['transplantEcUrgentlow']
        }
      }

      if(this.envsPrefix[idx] == 'waterLevel'){
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `water_level`}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
          })
          this.sortChart()
          this.loading = false
        })
      }else if(this.envsPrefix[idx] == 'waterTemp'){
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `water_temp_value`}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/100])
          })
          this.sortChart()
          this.loading = false
        })
      }else if(this.envsPrefix[idx] == 'pureLevelAbc'){
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `pure_level_ab`}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
          })
          this.sortChart()
          this.loading = false
        })
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `pure_level_c`}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, log.value/1])
          })
          this.sortChart()
          this.loading = false
        })
      }else if(this.envsPrefix[idx] == 'dataReceive'){
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `data_receive`, createEmpty: 'true'}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            log.value == 'N/A' || log.value == null ? this.envChartSeries.push([key, 0]) : this.envChartSeries.push([key, 1])
          })
          this.sortChart()
          this.loading = false
        })
      }else{
        this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd, endDate: this.endYmd, field: `${this.envsPrefix[idx]}_value`}).then(resp => {
          getItems(resp).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            const prefix = this.envsPrefix[idx]
            if(prefix == 'temp' || prefix == 'humidity' || prefix == 'ph') {
              this.envChartSeries.push([key, log.value/100])
            }else if(prefix == 'ec') {
              this.envChartSeries.push([key, log.value/1000])
            }else {
              this.envChartSeries.push([key, log.value/1])
            }
          })
          this.sortChart()
          this.loading = false
        })
      }
      this.getNotificationWithDate(idx)
    }
  }
}
</script>
