<template>
  <div style="height: 100%; min-height: 230px;">
    <highcharts v-if="chartSeries && chartSeries.length" :options="chartOptions" v-bind:style="{ height: '100%', marginLeft: '3%', marginRight: '3%'}"></highcharts>
    <button v-show="showLeftRightButton" class="btn rounded-pill btn-secondary btn-left" @click="buttonLeftRight('left')"><b-icon icon="chevron-left" font-scale="1"></b-icon></button>
    <button v-show="showLeftRightButton" class="btn rounded-pill btn-secondary btn-right" @click="buttonLeftRight('right')"><b-icon icon="chevron-right" font-scale="1"></b-icon></button>
    <div v-if="!(chartSeries && chartSeries.length)" class="text-center" v-bind:style="{ height: '100%'}">
      <span v-if="loading" class="spinner farm-chart-spinner" style="top: 70px;"></span>
      <span v-else style="top: 70px;"><br><br><br>No Data</span>
    </div>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import {getRefinedHighchartSeriesData, getHighchartYaxisMinMax, timestampToDate} from '@/core/services/funcs.js'
import i18n from "@/core/plugins/vue-i18n.js";


export default {
  name: "NotificationActionTimeChart",
  components: {
    highcharts: Chart
  },
  props: {
    category: String,
    chartSeries: Array,
    notificationChartSeries: Array,
    managementChartSeries: Array,
    limithigh: Number,
    limitlow: Number,
    loading: Boolean,
    buttonLeftRight: Function,
    showLeftRightButton: Boolean
  },
  data(){
    return {
      i18n: {
        urgentHigh: i18n.t('farm.deviationStatus.urgentHigh'),
        urgentLow: i18n.t('farm.deviationStatus.urgentLow')
      }
    }
  },
  computed: {
    chartOptions() {
      const refinedSeriesData = getRefinedHighchartSeriesData(this.chartSeries)
      const refinedNotificationSeriesData = getRefinedHighchartSeriesData(this.notificationChartSeries)
      let managementChartSeriesData = this.managementChartSeries.map(item => {
        if(refinedSeriesData[0][0] < item.x && refinedSeriesData[refinedSeriesData.length-1][0] > item.x){
          const idx = refinedSeriesData.findIndex(s => s[0] >= item.x)
          item.y = refinedSeriesData[idx][1]
        }
        return item
      })
      managementChartSeriesData = managementChartSeriesData.filter(m => m.y !== undefined && m.y !== null)

      const options = {
        chart: {
          zoomType: 'x,y',
          backgroundColor: '#F9FBFF'
        },
        title: {
          text: `<span class="font-weight-bold">${this.category}</span>`,
          align: 'left'
        },
        plotOptions: {
          series: {
            turboThreshold: 100000
          }
        },
        yAxis: {
          plotLines: [],
          title: {
            text: ''
          }
        },
        xAxis: {
          plotLines: [],
          type: 'datetime',
          labels: {
            format: '{value:%y.%m.%d %H:%M}'
          },
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (this.y === 0) {
              return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span>
               <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span>
               <br> <span style="font-size: 10px;">(0 혹은 이상경보 두배 이상의 이상치)</span>
               ${typeof(this.key) === 'number' ? '' : '<br> <span style="font-size: 10px;">(' + this.key + ')</span>'}`
            } else {
              return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span>
               <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span>
               ${typeof(this.key) === 'number' ? '' : '<br> <span style="font-size: 10px;">(' + this.key + ')</span>'}`
            }
          }
        },
        series: [{
          name: this.category,
          threshold: this.limit,
          tooltip: {
            valueDecimals: 2
          },
          zones: this.limitlow !== null ? [{
            value: this.limitlow,
            color:'red'
          },{
            value: this.limithigh,
            color:'skyblue'
          },{
            color:'red'
          }] : [],
          data: refinedSeriesData,
          marker: {
            enabled: false
          }
        },{
          data: refinedNotificationSeriesData,
          type: 'scatter',
          name: this.category + ' ' + i18n.t('farm.deviationStatus.deviation'),
          marker: {enabled: true, radius: 4},
          tooltip: {
            headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
            pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
          }
        },{
          data: managementChartSeriesData,
          type: 'scatter',
          name: this.category + ' ' + i18n.t('farm.deviationStatus.action'),
          marker: {enabled: true, radius: 6, symbol: 'triangle-down'},
          tooltip: {
            headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
            pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
          }
        }
        ]
      };

      const yAxisMinMax = getHighchartYaxisMinMax(this.chartSeries, this.limitlow, this.limithigh)
      options.yAxis.min =  yAxisMinMax.min - yAxisMinMax.min/10
      options.yAxis.max = yAxisMinMax.max + yAxisMinMax.max/10
      options.yAxis.startOnTick = false
      options.yAxis.endOnTick = false

      if(this.limithigh || this.limitlow){
        options.yAxis.plotLines.push({
          value: (this.limithigh || 0),
          width: 2,
          color: 'rgb(153,000,153)',
          label: {
            text: `${this.i18n.urgentHigh}( ` + (this.limithigh || 0) + ' )',
            style: {
              color: 'rgb(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'right'
          },
        });

        options.yAxis.plotLines.push({
          value: (this.limitlow || 0),
          width: 2,
          color: 'rgba(153,000,153)',
          label: {
            text: `${this.i18n.urgentLow}( ` + (this.limitlow || 0) + ' )',
            style: {
              color: 'rgba(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'right'
          },
        });
      }

      return options
    }
  }
}
</script>

<style>
  .btn-left{
    position: absolute;
    opacity: 0.4;
    top: 50%;
    left : 1%;
  }
  .btn-left:hover{
    position: absolute;
    opacity: 0.8;
    top: 50%;
    left : 1%;
  }
  .btn-right{
    position: absolute;
    opacity: 0.4;
    top: 50%;
    right : 1%;
  }
  .btn-right:hover{
    position: absolute;
    opacity: 0.8;
    top: 50%;
    right : 1%;
  }
</style>
